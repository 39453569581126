<template>
  <div :class="`admin-${isDaily ? 'daily-imports' : 'imports'}`">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddSaleImport = true"
        >{{ $t("import") }}
      </ebp-button>
    </portal>

    <advanced-data-table
      v-if="render"
      :action="`${isDaily ? 'dailySaleImports' : 'saleImports'}/get`"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(is_legacy)="{item}">
        {{ item.legacy_id ? "Yes" : "No" }}
      </template>

      <template #cell(imported_at)="{item}">
        {{ formatDate(item.imported_at) }}
      </template>

      <template #cell(records_count)="{item}">
        {{ item.failed }}
      </template>

      <template #cell(sales_channel)="{item}">
        {{ item.sales_channel ? item.sales_channel.name : "None" }}
      </template>

      <template #cell(processor)="{item}">
        {{ getProcessorName(item.processor_type) }}
      </template>

      <template #cell(notes)="{item}">
        <ebp-note-button :text="truncate(item.notes)" />
      </template>

      <template #cell(total_royalty)="{item}">
        {{
          formatCurrency(
            item.total_royalty,
            item.currency ? item.currency.code : "GBP"
          )
        }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1"
            >{{ $t(item.status) }}
          </b-badge>
        </h5>
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="success"
          size="sm"
          class="ml-1"
          v-tooltip.top-center="$t('view')"
          :to="{
            name: isDaily ? 'ViewDailySaleRecords' : 'ViewSaleRecords',
            query: {
              sales_import_id: item.id,
              step: ['draft', 'processing'].includes(item.status)
                ? 2
                : undefined
            }
          }"
        >
          <i class="bx bxs-show"></i>
        </ebp-button>
        <ebp-button
          variant="danger"
          size="sm"
          class="ml-1"
          v-tooltip.top-center="$t('rollback')"
          :loading="rollingBack === item.id"
          @click="rollback(item)"
        >
          <i class="bx bx-undo"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- Date range filter -->
        <adt-filter :label="`Import date`">
          <date-picker
            v-model="filters.date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>

        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-form-select
            :options="addAllOption(salesImportStatuses)"
            v-model="filters.status"
          ></b-form-select>
        </adt-filter>

        <!-- Sales Channel filter -->
        <adt-filter :label="$t('sales-channel')">
          <item-search
            action="salesChannels/searchParents"
            label="name"
            trackBy="id"
            v-model="filters.sales_channel_id"
          />
        </adt-filter>
      </template>
    </advanced-data-table>

    <!-- SalesChannel add modal -->
    <ebp-modal
      v-if="render"
      :open.sync="showAddSaleImport"
      :tabs="addTabs"
      width="50%"
    >
      <template #error>
        <error :err.sync="error" />
      </template>

      <!-- Edit details -->
      <template #import>
        <AddSaleImport @added="added" :isDaily="isDaily" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import saleImportSchema from "@/validation-schemas/sale-import";
import salesImportStatuses from "@/fixtures/sales-import-statuses";
import ItemSearch from "@/components/core/item-search";
import DatePicker from "@/components/core/DatePicker";

import AddSaleImport from "@/views/admin/sales/imports/Add";
import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";
import EbpNoteButton from "../../../../components/ebp-note-button";

export default {
  name: "SaleImports",
  components: {
    EbpNoteButton,
    AdvancedDataTable,
    AddSaleImport,
    ItemSearch,
    AdtFilter,
    DatePicker
  },
  data() {
    return {
      salesImportStatuses: salesImportStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        processor_type: null,
        sales_channel_id: null,
        status: null,
        date_range: null
      },
      rollingBack: false,
      render: true
    };
  },
  computed: {
    ...mapState("saleImports", ["processorTypes"]),
    ...mapState("dailySaleImports", ["dailyProcessorTypes"]),
    isDaily() {
      return this.$route.meta.daily;
    },
    headers() {
      const headers = [
        "filename",
        { key: "is_legacy", label: "Is Legacy" },
        { key: "sales_channel", label: "sales-channel" },
        { key: "processed", label: "records" },
        "processor",
        { key: "imported_at", label: "imported-at" },
        "status",
        "notes"
      ];

      if (!this.isDaily) {
        headers.push({ key: "total_royalty", label: "total-royalty" });
      }

      return headers;
    }
  },
  methods: {
    async rollback(saleImport) {
      let message =
        "This will revert the import and remove included sale records. Are you sure?";

      if (!this.isDaily) {
        message =
          "This will revert the import and remove all associated royalty and sale records. Are you sure?";
      }

      if (!confirm(message)) return;

      this.rollingBack = saleImport.id;

      try {
        await this.$store.dispatch(
          `${this.isDaily ? "dailySaleImports" : "saleImports"}/cancel`,
          saleImport.id
        );
        this.$refs.table.change("id", saleImport.id, {
          ...saleImport,
          status: "rolled_back"
        });
        this.$refs.table.change("id", saleImport.id);
      } catch (err) {
        console.error(err);
      }

      this.rollingBack = false;
    },
    getProcessorName(code) {
      if (this.isDaily) {
        const type = find(this.dailyProcessorTypes, o => o.code === code);
        return type ? type.name : "None";
      } else {
        const type = find(this.processorTypes, o => o.code === code);
        return type ? type.name : "None";
      }
    },
    truncate,
    added(data) {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();

      this.$nextTick(() =>
        this.$router.push({
          name: this.isDaily ? "ViewDailySaleRecords" : "ViewSaleRecords",
          query: {
            sales_import_id: data.id,
            step: 2,
            failed: data.failed,
            processed: data.processed,
            totalParsed: data.total_records,
            totalSales: data.total_sale_value,
            totalCommision: data.total_commission_value,
            totalRoyalty: data.total_royalty,
            totalSaleCount: data.total_sales_count
          }
        })
      );
    },
    edit(saleImport) {
      this.editedItem = saleImport;
      this.open = true;
    },
    async save() {
      this.error = null;
      const saleImport = this.editedItem;
      const validation = saleImportSchema.validate(saleImport);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("saleImports/update", saleImport);
          this.$refs.table.change("id", saleImport.id, saleImport);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  },
  watch: {
    isDaily() {
      this.render = false;
      this.$nextTick(() => (this.render = true));
    }
  }
};
</script>

<style></style>
