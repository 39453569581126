<template>
  <div class="admin-add-sales-import">
    <error :err.sync="error" />
    <template v-if="loading">
      <template v-if="progress < 100">
        <b-progress
          :value="progress"
          show-progress
          animated
          height="1.6rem"
        ></b-progress>
        <h3 class="text-center mt-5">
          {{ $t("uploading") }}
        </h3>
      </template>
      <template v-else>
        <spinner />
        <h3 class="text-center mt-5">
          {{ $t("processing") }}
        </h3>
      </template>
    </template>
    <form-generator
      :elements="elements"
      :data="salesImport"
      :handleUpdate="({ key }, v) => (salesImport[key] = v)"
      v-if="render && !loading"
    />
    <div
      v-if="this.shouldShowWarning"
      v-html="this.shouldShowWarning"
      class="alert alert-warning processSteps"
    ></div>
  </div>
</template>

<script>
import saleImportSchema from "@/validation-schemas/sale-import";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

function initialState() {
  return {
    salesImport: {
      filename: null,
      file: null,
      sales_channel: null,
      sales_channel_id: null,
      processor_type: null,
      date_of_sale: null,
      notes: null
    },
    loading: false,
    error: null,
    render: true,
    progress: 0,
    shouldShowWarning: false
  };
}

export default {
  components: { FormGenerator },
  name: "admin-add-sales-import",
  props: {
    isDaily: Boolean
  },
  data() {
    return initialState();
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels");
    this.$store.dispatch(
      `${
        this.$props.isDaily ? "dailySaleImports" : "saleImports"
      }/getProcessorTypes`
    );
    window.onbeforeunload = this.beforeUnload;
  },
  computed: {
    ...mapState("ancillaries", ["allSalesChannels"]),
    ...mapState("saleImports", ["processorTypes"]),
    ...mapState("dailySaleImports", {
      dailyProcessorTypes: "processorTypes"
    }),
    elements() {
      return [
        {
          type: "dropzone",
          events: {
            input: files => {
              if (files.length) {
                this.salesImport.file = files[0];
                this.salesImport.filename = files[0].name;
              } else {
                this.salesImport.filename = null;
                this.salesImport.file = null;
              }
            }
          }
        },
        {
          key: "processor_type",
          label: "processor",
          type: "select",
          attrs: {
            options: [
              {
                text: this.$t("select-processor"),
                value: null
              }
            ].concat(
              this[
                this.$props.isDaily ? "dailyProcessorTypes" : "processorTypes"
              ].map(p => ({
                text: p.name,
                value: p.code
              }))
            )
          },
          events: {
            change: value => {
              this.determineWarning(value);
            }
          }
        },
        {
          key: "sales_channel",
          label: "sales-channel",
          type: "multiselect",
          attrs: {
            options: this.allSalesChannels,
            trackBy: "id",
            label: "name",
            multiple: false,
            loading: !this.allSalesChannels.length
          }
        },
        {
          key: "date_of_sale",
          label: "date-of-sale",
          helpText:
            "Optional - if a date of sale cannot reliably be determined from the import, this date will be used",
          type: "date",
          className: "stand-alone"
        },
        {
          key: "notes",
          label: "notes",
          type: "text",
          className: "mb-5"
        },
        {
          text: "import",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    beforeUnload() {
      if (this.loading && this.progress === 100) {
        return "";
      }
    },
    determineWarning(processorCode) {
      let warning = false;

      if (processorCode === "apple-paid") {
        warning = `<strong>Please follow the pre-processing steps for Apple Paid files before importing:</strong>
            <ol class="steps">
              <li>Open a new (blank) Excel file</li>
              <li>Click Data > Get & Transform Data (first button group) > From Text/CSV</li>
              <li>Select the respective Apple .txt file</li>
              <li>Look at the preview window. If the data appears correctly formatted in columns, proceed to Step 6. Otherwise continue</li>
              <li>Change Delimiter dropdown to Tab</li>
              <li>Once the columns appear correct in the preview window, click "Load" on the bottom right</li>
              <li>Save this new file and use it for the import above</li>
            </ol>`;
      }

      if (processorCode === "borrowbox") {
        warning = `<strong>Please follow the following pre-processing steps for BorrowBox files:</strong>
        <ol class="steps">
            <li>Open the file in Excel and save it as a .CSV</li>
        </ol>
`;
      }

      if (processorCode === "apple-free") {
        warning = `<strong>Please follow the pre-processing steps for Apple Paid files before importing:</strong>
            <ol class="steps">
              <li>Open a new (blank) Excel file</li>
              <li>Click Data > Get & Transform Data (first button group) > From Text/CSV</li>
              <li>Select the respective Apple .txt file</li>
              <li>Look at the preview window. If the data appears correctly formatted in columns, proceed to Step 6. Otherwise continue</li>
              <li>Change Delimiter dropdown to Tab</li>
              <li>Once the columns appear correct in the preview window, click "Load" on the bottom right</li>
              <li><strong>Change the ISBN column to type Number and remove the decimals by decreasing the decimal values to 0.</strong></li>
              <li>Save this new file and use it for the import above</li>
            </ol>`;
      }

      this.shouldShowWarning = warning;
    },
    async add() {
      this.error = null;
      const { salesImport } = this;
      const validation = saleImportSchema.validate(
        salesImport,
        this.joiOptions
      );
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch(
            `${
              this.$props.isDaily ? "dailySaleImports" : "saleImports"
            }/create`,
            {
              salesImport,
              onUploadProgress: e => {
                this.progress = (e.loaded / e.total) * 100;
              }
            }
          );

          // Reset data
          this.$emit("added", res.data);
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    "salesImport.sales_channel"(channel) {
      this.salesImport.sales_channel_id = channel ? channel.id : null;
    }
  }
};
</script>

<style lang="scss">
.processSteps {
  font-size: 13px;
  text-align: left;

  strong {
    margin-bottom: 15px;
  }
}

ol.steps {
  li {
    font-size: 12px;
    text-align: left;
  }
}
</style>
