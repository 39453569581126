<template>
  <vue-date-picker v-on="$listeners" v-bind="attrs" class="ebp-date-picker" />
</template>

<script>
import VueDatePicker from "vue2-datepicker";

export default {
  name: "date-picker",
  components: {
    VueDatePicker
  },
  computed: {
    attrs() {
      return {
        format: "DD-MMM-YYYY",
        ...this.$attrs
      };
    }
  }
};
</script>
